export function sortObjects<
  T extends { [K in TIdKey]: string },
  TIdKey extends string = "id",
>(objects: T[], desiredOrder: string[], idKey: TIdKey = "id" as TIdKey): T[] {
  return objects.toSorted((a, b) => {
    const indexA = desiredOrder.indexOf(a[idKey])
    const indexB = desiredOrder.indexOf(b[idKey])

    // If a is not in the desired order list, use the length of the desiredOrder array
    const fallbackA = indexA === -1 ? desiredOrder.length : indexA
    // If b is not in the desired order list, use the length of the desiredOrder array
    const fallbackB = indexB === -1 ? desiredOrder.length : indexB

    // If both have the same index, maintain their original order
    if (fallbackA === fallbackB) {
      return objects.indexOf(a) - objects.indexOf(b)
    }

    return fallbackA - fallbackB
  })
}

export const moveObjectUp = (order: string[], object: string): string[] => {
  const index = order.indexOf(object)
  if (index === 0) return order

  const newOrder = [...order]
  newOrder.splice(index, 1)
  newOrder.splice(index - 1, 0, object)
  return newOrder
}

export const moveObjectDown = (order: string[], object: string): string[] => {
  const index = order.indexOf(object)
  if (index === order.length - 1) return order
  const newOrder = [...order]
  newOrder.splice(index, 1)
  newOrder.splice(index + 1, 0, object)
  return newOrder
}
