import { useCallback, useMemo } from "react"
import {
  MutateOptions,
  UseQueryOptions,
  useQueryClient,
} from "@tanstack/react-query"
import { UpdateOperation, useOptimisticBuffer } from "@daybridge/optimism"
import { useAccountQuery, useUpdateAccountMutation } from "../_gen/hooks"
import { GraphQLError } from "../../lib/graphql/errors"
import {
  AccountQuery,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from "../_gen/operations"
import { ClientData } from "./clientData"

export type ClientDataValueAndSetter<K extends keyof ClientData> = [
  ClientData[K],
  (value: ClientData[K]) => void,
]

export type ClientDataQueryOptions<K extends keyof ClientData> =
  UseQueryOptions<AccountQuery, GraphQLError, ClientData[K]>

export type ClientDataMutationOptions = MutateOptions<
  UpdateAccountMutation,
  GraphQLError | undefined,
  UpdateAccountMutationVariables
>

export function useClientData<K extends keyof ClientData>(
  key: K,
  options?: ClientDataQueryOptions<K>,
): ClientDataValueAndSetter<K> {
  const queryClient = useQueryClient()
  const optimisticOperations = useOptimisticBuffer<
    unknown,
    UpdateAccountMutationVariables
  >("ClientData")

  const select = useCallback(
    (values: AccountQuery): ClientData[K] => {
      const clientData = values.currentAccount?.clientData

      // Client data is a list of objects with a `key` and `value` field.
      // Convert this to a map of key -> value.
      const clientDataMap = new Map(
        clientData?.map(({ key, value }) => [key, value]),
      )

      return clientDataMap.get(key) as ClientData[K]
    },
    [key],
  )

  // Fetch the preference from the `AccountQuery`.
  const { data } = useAccountQuery(undefined, {
    ...options,

    // Select appears after `queryOptions` so that the caller can't overwrite it
    select,

    // Don't refetch on mount because this would cause an enormous amount of
    // unnecessary refetches.
    refetchOnMount: false,
  })

  // This is safe because we pre-fetch the data server-side, so it won't be undefined.
  const valueFromServer = data as ClientData[K]

  // Check if there is a value in the update buffer for this preference
  const valueFromBuffer = optimisticOperations.operations.findLast(
    (operation): operation is UpdateOperation<UpdateAccountMutationVariables> =>
      operation.type === "update" &&
      operation.payload.input.patch.clientData?.[0].key === key,
  )?.payload.input.patch.clientData?.[0].value as ClientData[K]

  const value = useMemo(
    () => valueFromBuffer ?? valueFromServer,
    [valueFromBuffer, valueFromServer],
  )

  const { mutateAsync } = useUpdateAccountMutation({
    onMutate: useCallback(
      (
        variables: UpdateAccountMutationVariables,
      ): UpdateOperation<UpdateAccountMutationVariables> => {
        const operation = optimisticOperations.update(key, variables)
        return operation
      },
      [key, optimisticOperations],
    ),
    onSettled: useCallback(
      async (
        _data: UpdateAccountMutation | undefined,
        _error: GraphQLError | null | undefined,
        variables: UpdateAccountMutationVariables | undefined,
        context: UpdateOperation<UpdateAccountMutationVariables> | undefined,
      ) => {
        await queryClient.invalidateQueries(useAccountQuery.getKey())
        // Clear the optimistic update buffer for this preference
        if (context) {
          optimisticOperations.complete(context.operationId)
        }
      },
      [optimisticOperations, queryClient],
    ),
  })

  const mutate = useCallback(
    async (
      value: ClientData[K],
      mutationOptions?: ClientDataMutationOptions,
    ): Promise<ClientData[K]> => {
      await mutateAsync(
        {
          input: {
            patch: {
              clientData: [
                {
                  key,
                  value,
                },
              ],
            },
          },
        },
        mutationOptions,
      )
      return value
    },
    [key, mutateAsync],
  )

  return useMemo(() => [value, mutate], [value, mutate])
}
