import { DateTime, DateTimeFormatOptions } from "luxon"
import { useCallback } from "react"
import { useLocale } from "next-intl"
import { useClientData } from "../data/client-data/useClientData"
import { useNow } from "./useNow"

export type TimeFormatterOptions = Omit<DateTimeFormatOptions, "12-hour"> & {
  // `omitMinutesIfZero` is a custom option that we add to the standard
  // `DateTimeFormatOptions` to allow omitting the minutes if they are zero.
  // This will only apply for 12 hour time formats, for 24 hour time formats
  // the minutes will always be shown.
  // Defaults to true if not provided
  omitMinutesIfZero?: boolean

  // `omitYearIfCurrent` is a custom option that we add to the standard
  // `DateTimeFormatOptions` to allow omitting the year if it's the current
  // year.
  omitYearIfCurrent?: boolean
}

export const useTimeFormatter = () => {
  const locale = useLocale()
  let hourFormat: "12_hour" | "24_hour"
  try {
    ;[hourFormat] = useClientData("webTimeFormat")
  } catch (e) {
    hourFormat = "24_hour"
  }
  const now = useNow(60 * 60 * 1000)

  return useCallback(
    (time: DateTime, opts: TimeFormatterOptions) => {
      return time
        .toLocaleString(
          {
            ...opts,
            hourCycle: hourFormat === "12_hour" ? "h12" : "h23",
            minute:
              opts.omitMinutesIfZero !== false &&
              time.minute === 0 &&
              hourFormat === "12_hour"
                ? undefined
                : opts.minute,
            year:
              opts.omitYearIfCurrent === true && time.year === now.year
                ? undefined
                : opts.year,
          },
          { locale },
        )
        .replace(/\s+am/g, " AM")
        .replace(/\s+pm/g, " PM")
        .replace(/(^|\P{Letter})\p{Letter}/gu, (match) => match.toUpperCase())
    },
    [locale, hourFormat, now],
  )
}
