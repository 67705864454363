import { useEffect } from "react"
import { Operation } from "./types"

export const useUnloadWarning = (values: Map<string, Operation[]>) =>
  useEffect(() => {
    const hasUnsavedChanges = () => {
      return Array.from(values.values()).some(
        (operations) => operations.length > 0,
      )
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges()) {
        event.preventDefault()
        event.returnValue = ""
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [values])
