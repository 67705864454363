import { InfiniteData } from "@tanstack/react-query"
import { sortObjects } from "./sort"
import { Operation } from "./types"

export const applyOperations = <
  TQueryNetworkObject,
  TDomainObject extends { [K in TIdKey]: string },
  TCreateVariables,
  TUpdateVariables,
  TUpdateContext,
  TTemporaryObject extends { [K in TIdKey]: string } = TDomainObject,
  TIdKey extends string = "id",
>(
  result: InfiniteData<TQueryNetworkObject>,
  operations: Operation<TCreateVariables, TUpdateVariables, TUpdateContext>[],
  fromNetworkObject: (
    networkObject: InfiniteData<TQueryNetworkObject>,
    pendingOperations: Operation<
      TCreateVariables,
      TUpdateVariables,
      TUpdateContext
    >[],
  ) => TDomainObject[],
  createTemporaryDomainObject?: (
    createVariables: TCreateVariables,
    domainObjects: TDomainObject[],
  ) => TTemporaryObject | null,
  applyTemporaryDomainObject?: (
    temporaryDomainObject: TTemporaryObject,
    domainObjects: TDomainObject[],
  ) => TDomainObject[],
  findDomainObjectToPatch?: (
    domainObjects: TDomainObject[],
    patch: TUpdateVariables,
  ) => number,
  applyPatch?: (
    domainObject: TDomainObject,
    patch: TUpdateVariables,
  ) => TDomainObject,
  transform?: (domainObjects: TDomainObject[]) => TDomainObject[],
  deleteObject?: (
    domainObjects: TDomainObject[],
    id: string,
  ) => TDomainObject[],
  sort?: (
    domainObjects: TDomainObject[],
    order: string[],
    idKey: TIdKey,
    group?: string,
  ) => TDomainObject[],
  idKey: TIdKey = "id" as TIdKey,
) => {
  let domainObjects = fromNetworkObject(result, operations)

  operations.forEach((operation) => {
    switch (operation.type) {
      case "create": {
        if (!createTemporaryDomainObject) return
        const { payload } = operation
        const temporaryDomainObject = createTemporaryDomainObject(
          payload,
          domainObjects,
        )
        if (!temporaryDomainObject) return

        if (applyTemporaryDomainObject) {
          domainObjects = applyTemporaryDomainObject(
            temporaryDomainObject,
            domainObjects,
          )
        } else {
          domainObjects.push(temporaryDomainObject as unknown as TDomainObject)
        }
        break
      }
      case "update": {
        if (!applyPatch) {
          return
        }
        const { id, payload } = operation
        let index: number
        if (findDomainObjectToPatch) {
          index = findDomainObjectToPatch(domainObjects, payload)
        } else {
          index = domainObjects.findIndex((obj) => obj[idKey] === id)
        }
        if (index !== -1) {
          const domainObject = domainObjects[index]
          const updatedDomainObject = applyPatch(domainObject, payload)
          domainObjects.splice(index, 1, updatedDomainObject)
        }
        break
      }
      case "delete": {
        const { id } = operation
        if (deleteObject) {
          domainObjects = deleteObject(domainObjects, operation.id)
          break
        } else {
          const index = domainObjects.findIndex((obj) => obj[idKey] === id)
          if (index !== -1) {
            domainObjects.splice(index, 1)
          }
          break
        }
      }
      case "reorder": {
        const { order, group } = operation
        domainObjects = (sort || sortObjects)(
          domainObjects,
          order,
          idKey,
          group,
        )
        break
      }
    }
  })

  return transform ? transform(domainObjects) : domainObjects
}
