"use client"

import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { v4 } from "uuid"
import {
  CreateOperation,
  DeleteOperation,
  Operation,
  ReorderOperation,
  UpdateOperation,
} from "./types"
import { useUnloadWarning } from "./warning"

export type OptimismFunctions = {
  create: (
    query: string,
    payload: unknown,
    idempotencyKey?: string,
  ) => CreateOperation<unknown>
  update: (
    query: string,
    id: string,
    payload: unknown,
    context: unknown,
  ) => UpdateOperation<unknown, unknown>
  delete: (query: string, id: string) => DeleteOperation
  reorder: (
    query: string,
    order: string[],
    onSettle?: () => Promise<void> | void,
    group?: string,
  ) => ReorderOperation
  complete: (query: string, operationId: string) => void
}

export type OptimismContext = {
  values: Map<string, Operation[]>
} & OptimismFunctions

const optimismContext = createContext<OptimismContext>({
  values: new Map(),
  create: () => {
    throw new Error("create can only be used within an OptimismProvider")
  },
  update: () => {
    throw new Error("update can only be used within an OptimismProvider")
  },
  delete: () => {
    throw new Error("delete can only be used within an OptimismProvider")
  },
  reorder: () => {
    throw new Error("reorder can only be used within an OptimismProvider")
  },
  complete: () => {
    throw new Error("complete can only be used within an OptimismProvider")
  },
})

export const OptimismContextProvider = optimismContext.Provider
export const useOptimismContext = () => useContext(optimismContext)

export const useOptimisticBuffer = <
  CreatePayload = unknown,
  UpdatePayload = unknown,
  UpdateContext = unknown,
>(
  queryName: string,
) => {
  // Isolate the operations for the given query so that we only re-render
  // when the operations for the given query change.
  const {
    values,
    create,
    update,
    delete: remove,
    reorder,
    complete,
  } = useOptimismContext()
  const operations = useMemo(
    () => values.get(queryName) || [],
    [values, queryName],
  )

  const createOperation = useCallback(
    (
      payload: CreatePayload,
      idempotencyKey?: string,
    ): CreateOperation<CreatePayload> =>
      create(
        queryName,
        payload,
        idempotencyKey,
      ) as CreateOperation<CreatePayload>,
    [create, queryName],
  )

  const updateOperation = useCallback(
    (
      id: string,
      payload: UpdatePayload,
      context?: UpdateContext,
    ): UpdateOperation<UpdatePayload, UpdateContext> =>
      update(queryName, id, payload, context) as UpdateOperation<
        UpdatePayload,
        UpdateContext
      >,
    [queryName, update],
  )

  const removeOperation = useCallback(
    (id: string): DeleteOperation => remove(queryName, id),
    [queryName, remove],
  )

  const reorderOperation = useCallback(
    (
      order: string[],
      onSettle?: () => Promise<void> | void,
      group?: string,
    ): ReorderOperation => {
      return reorder(queryName, order, onSettle, group)
    },
    [queryName, reorder],
  )

  const completeOperation = useCallback(
    (operationId: string): void => complete(queryName, operationId),
    [queryName, complete],
  )

  return useMemo(
    () => ({
      operations: operations as Operation<
        CreatePayload,
        UpdatePayload,
        UpdateContext
      >[],
      create: createOperation,
      update: updateOperation,
      delete: removeOperation,
      reorder: reorderOperation,
      complete: completeOperation,
    }),
    [
      operations,
      createOperation,
      updateOperation,
      removeOperation,
      reorderOperation,
      completeOperation,
    ],
  )
}

export const OptimismProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [map, setMap] = useState<Map<string, Operation[]>>(
    new Map<string, Operation[]>(),
  )

  const create = useCallback(
    (
      query: string,
      payload: unknown,
      idempotencyKey?: string,
    ): CreateOperation<unknown> => {
      const operation: CreateOperation<unknown> = {
        operationId: idempotencyKey || v4(),
        type: "create",
        payload,
        idempotencyKey,
      }
      setMap((prev) => {
        const operations = prev.get(query) || []
        // State update is invoked twice in dev mode, so
        // we need to check if the operation already exists
        if (operations.some((op) => op.operationId === operation.operationId)) {
          return prev
        }
        const newValue = new Map(prev.set(query, [...operations, operation]))
        console.log(`[create] ${query}`, newValue)
        return newValue
      })
      return operation
    },
    [],
  )

  const update = useCallback(
    (
      query: string,
      id: string,
      payload: unknown,
      context: unknown,
    ): UpdateOperation<unknown> => {
      const operationId = v4()
      const operation: UpdateOperation<unknown> = {
        operationId,
        type: "update",
        id,
        payload,
        context,
      }
      setMap((prev) => {
        const operations = prev.get(query) || []
        // State update is invoked twice in dev mode, so
        // we need to check if the operation already exists
        if (operations.some((op) => op.operationId === operation.operationId)) {
          return prev
        }
        const newValue = new Map(prev.set(query, [...operations, operation]))
        console.log(`[update] ${query} ${id}`, newValue)
        return newValue
      })
      return operation
    },
    [],
  )

  const remove = useCallback((query: string, id: string): DeleteOperation => {
    const operationId = v4()
    const operation: DeleteOperation = {
      operationId,
      type: "delete",
      id,
    }
    setMap((prev) => {
      const operations = prev.get(query) || []
      // State update is invoked twice in dev mode, so
      // we need to check if the operation already exists
      if (operations.some((op) => op.operationId === operation.operationId)) {
        return prev
      }
      const newValue = new Map(prev.set(query, [...operations, operation]))
      console.log(`[remove] ${query} ${id}`, newValue)
      return newValue
    })
    return operation
  }, [])

  const reorder = useCallback(
    (
      query: string,
      order: string[],
      onSettle?: () => Promise<void> | void,
      group = "default",
    ): ReorderOperation => {
      const operationId = v4()
      const executionTimeout = setTimeout(() => {
        // Mark the operation as completed so that this operation
        // can be deleted from the list of operations on settle
        setMap((prev) => {
          const operations = prev.get(query) || []
          const operation = operations.find(
            (op) => op.operationId === operationId,
          ) as ReorderOperation | undefined
          if (!operation) {
            return prev
          }
          const newValue = new Map(
            prev.set(
              query,
              operations.map((op) =>
                op.operationId === operationId
                  ? { ...operation, completed: true }
                  : op,
              ),
            ),
          )
          return newValue
        })

        void onSettle?.()
      }, 5000)

      const operation = {
        group,
        operationId,
        type: "reorder",
        order,
        executionTimeout,
        settled: false,
      } as const

      setMap((prev) => {
        const operations = prev.get(query) || []
        if (operations.some((op) => op.operationId === operation.operationId)) {
          return prev
        }

        const existingOperation = operations.find(
          (op) => op.type === "reorder" && op.group === group,
        ) as ReorderOperation | undefined

        let newValue: Map<string, Operation[]>
        if (existingOperation) {
          clearTimeout(existingOperation.executionTimeout)
          newValue = new Map(
            prev.set(
              query,
              operations.map((op) =>
                op.type === "reorder" && op.group === group ? operation : op,
              ),
            ),
          )
        } else {
          newValue = new Map(prev.set(query, [...operations, operation]))
        }

        console.log(`[reorder] ${query}`, newValue)
        return newValue
      })
      return operation
    },
    [],
  )

  const complete = useCallback((query: string, operationId: string): void => {
    setMap((prev) => {
      const operations = prev.get(query) || []
      // State update is invoked twice in dev mode, so
      // we need to check if the operation already exists
      if (!operations.some((op) => op.operationId === operationId)) {
        return prev
      }
      const newValue = new Map(
        prev.set(
          query,
          operations.filter(
            (operation) => operation.operationId !== operationId,
          ),
        ),
      )

      console.log(`[complete] ${query} ${operationId}`, newValue)
      return newValue
    })
  }, [])

  const value = useMemo(
    () => ({ values: map, create, update, delete: remove, reorder, complete }),
    [map, create, update, remove, reorder, complete],
  )

  useUnloadWarning(map)

  return (
    <OptimismContextProvider value={value}>{children}</OptimismContextProvider>
  )
}
