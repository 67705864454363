import React from "react"
import { cn } from "@daybridge/cn"
import { Icon } from "@daybridge/components"

type ItemIconProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  name: string
  theme?: "adaptive" | "neutral"
}

const ItemIconFn = React.forwardRef(
  (props: ItemIconProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { name, className, theme = "adaptive", ...rest } = props

    return (
      <div
        className={cn(
          "item-icon",
          "bg-gradient-to-b",
          theme === "adaptive"
            ? "from-adaptive-solid-object-highlight to-adaptive-solid-object"
            : "from-solid-object-highlight to-solid-object",
          "ring-1 ring-inset ring-tint-extra-heavy",
          "flex-shrink-0",
          "flex items-center justify-center text-white",
          "rounded-full",
          className,
        )}
        ref={ref}
        {...rest}
      >
        <Icon
          name={name}
          className={cn(
            "item-icon flex-shrink-0 p-[25%]",
            "w-[-webkit-fill-available]",
          )}
          style={{
            width: "-moz-available",
          }}
        />
      </div>
    )
  },
)
ItemIconFn.displayName = "ItemIcon"

export const ItemIcon = React.memo(ItemIconFn) as typeof ItemIconFn
