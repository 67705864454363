import { DateTime, Duration } from "luxon"

/**
 * `fallsInTimeRange` computes whether a given start and
 * end time falls within a specified time range.
 * An entry is said to fall within a time range if any part of that
 * entry touches any part of the range. For instance if an entry
 * starts at 8PM and the range ends at 8PM, that entry is in the range.
 *
 * @param start The start time of the entry
 * @param end The end time of the entry
 * @param rangeStart The start time of the range
 * @param rangeEnd The end time of the range
 */
export const fallsInTimeRange = (
  start: DateTime,
  end: DateTime,
  rangeStart: DateTime,
  rangeEnd: DateTime,
): boolean => {
  // Case 1
  // Entry starts and finishes before the range even starts
  if (start < rangeStart && end <= rangeStart) {
    return false
  }
  // Case 2
  // Entry starts and finishes after the range ends
  if (start >= rangeEnd && end >= rangeEnd) {
    return false
  }

  // Case 3
  // Entry starts and finishes within the range
  // Case 4
  // Entry starts before the range starts, and finishes after the range ends
  return true
}

/**
 * `durationIgnoringZoneDifferences` calculates the duration between a start
 * and and end date taking into account that these dates may
 * have different offsets applied to them. For instance, the start
 * date could be in UTC and the end date could be in BST.
 *
 * @param start The start date
 * @param end The end date
 */
export const durationIgnoringZoneDifferences = (
  start: DateTime,
  end: DateTime,
): Duration => {
  return end.diff(start).minus({ minutes: start.offset - end.offset })
}

// Given two dates, find the first hour between them where the offset changes
// This is the hour where the clocks go forward or back
// We do this by iterating through the hours between the two dates
// and checking if the offset changes
export const findTransitionTime = (
  windowStart: DateTime,
  windowEnd: DateTime,
): DateTime | undefined => {
  if (windowStart.offset === windowEnd.offset) {
    return undefined
  }

  // In case the window isn't exactly on hour boundaries, snap it to the nearest hour
  windowStart = windowStart.startOf("hour")
  windowEnd = windowEnd.startOf("hour")

  const hours = windowEnd.diff(windowStart).as("hours")
  for (let i = 0; i < hours; i++) {
    const hour = windowStart.plus({ hours: i })
    const nextHour = windowStart.plus({ hours: i + 1 })
    if (hour.offset !== nextHour.offset) {
      if (hour.offset > nextHour.offset) {
        // Clocks go back
        return hour
      } else {
        return nextHour
      }
    }
  }

  return undefined
}

// findClockChangeTime finds the time at which the clocks go forward or back
// as would be understood by most people. This is different from findTransitionTime.
export const findClockChangeTime_HourAccurateOnly = (
  windowStart: DateTime,
  windowEnd: DateTime,
): DateTime | undefined => {
  const transition = findTransitionTime(windowStart, windowEnd)
  if (transition === undefined) {
    return undefined
  }
  const delta = Math.abs(windowEnd.offset - windowStart.offset)
  if (windowEnd.offset > windowStart.offset) {
    // Clocks go forward
    return transition
      .toUTC(undefined, { keepLocalTime: true })
      .minus({ minutes: delta })
  } else {
    // Clocks go back
    return transition.plus({ hours: 1 }).plus({ minutes: delta })
  }
}

/**
 * There is a special case where the user has hidden some hours
 * and the clocks go forward on that day. In this case we need to insert
 * a spacer at the top of the day to make sure the correct number of cells
 * are shown in the day view. This function determines whether that condition
 * has been met.
 */
export const requiresDSTChangeSpacer = (
  constraints: { start: number; end: number } | undefined,
  day: DateTime,
): boolean => {
  const transition = findTransitionTime(day.startOf("day"), day.endOf("day"))
  return (
    constraints !== undefined &&
    transition !== undefined &&
    day.startOf("day").offset < day.endOf("day").offset &&
    transition.hour === constraints.start + 1
  )
}

export const roundTime = (dateTime: DateTime, minutes: number) =>
  dateTime.startOf("hour").plus({
    minutes: Math.floor(dateTime.minute / minutes) * minutes,
  })
