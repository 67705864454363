/* eslint-disable react-hooks/rules-of-hooks */
import {
  MutationFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { useOptimisticBuffer } from "./provider"
import {
  CreateOperation,
  DeleteOperation,
  ReorderOperation,
  UpdateOperation,
} from "./types"
import { moveObjectDown, moveObjectUp } from "./sort"
import {
  TCreateVariables,
  TUpdateVariables,
  TReorderVariables,
} from "./useOptimisticQuery"

export type OptimisticMutationConfig<
  TError,
  TCreateInput,
  TCreateIdempotencyKey extends string | undefined,
  TCreateNetworkObject,
  TUpdateInput,
  TUpdateNetworkObject,
  TUpdateContext,
  TDeleteVariables,
  TDeleteNetworkObject,
> = {
  queryName: string
  additionalInvalidationKeys?: QueryKey[]

  create?: {
    fetcher: MutationFunction<
      TCreateNetworkObject,
      TCreateVariables<TCreateInput, TCreateIdempotencyKey>
    >
    options?: UseMutationOptions<
      TCreateNetworkObject,
      TError,
      TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
      CreateOperation<TCreateVariables<TCreateInput, TCreateIdempotencyKey>>
    >
  }
  update?: {
    fetcher: MutationFunction<
      TUpdateNetworkObject,
      TUpdateVariables<TUpdateInput, TUpdateContext>
    >
    options?: UseMutationOptions<
      TUpdateNetworkObject,
      TError,
      TUpdateVariables<TUpdateInput, TUpdateContext>,
      UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>>
    >
  }
  delete?: {
    fetcher: MutationFunction<TDeleteNetworkObject, TDeleteVariables>
    options?: UseMutationOptions<
      TDeleteNetworkObject,
      TError,
      TDeleteVariables,
      DeleteOperation
    >
  }
  reorder?: {
    fetcher: MutationFunction<unknown, TReorderVariables>
    options?: UseMutationOptions<
      unknown,
      TError,
      TReorderVariables,
      ReorderOperation
    >
  }
}

export const useOptimisticMutations = <
  TError,
  TCreateInput,
  TCreateIdempotencyKey extends string | undefined,
  TCreateNetworkObject,
  TUpdateInput,
  TUpdateNetworkObject,
  TUpdateContext,
  TDeleteVariables extends { input: { id: string } },
  TDeleteNetworkObject,
>(
  config: OptimisticMutationConfig<
    TError,
    TCreateInput,
    TCreateIdempotencyKey,
    TCreateNetworkObject,
    TUpdateInput,
    TUpdateNetworkObject,
    TUpdateContext,
    TDeleteVariables,
    TDeleteNetworkObject
  >,
) => {
  const {
    queryName,
    create: createConfig,
    update: updateConfig,
    delete: deleteConfig,
    reorder: reorderConfig,
    additionalInvalidationKeys,
  } = config

  const optimism = useOptimisticBuffer<
    TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
    TUpdateVariables<TUpdateInput, TUpdateContext>,
    TUpdateContext
  >(queryName)
  const queryClient = useQueryClient()
  const defaultOnError = queryClient.getDefaultOptions().mutations?.onError

  /*-------------------*/
  /* Creation
  /*-------------------*/
  let create:
    | UseMutationResult<
        TCreateNetworkObject,
        TError,
        TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
        CreateOperation<TCreateVariables<TCreateInput, TCreateIdempotencyKey>>
      >
    | undefined
  if (createConfig) {
    const onCreateMutate = useCallback(
      (
        variables: TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
      ): CreateOperation<
        TCreateVariables<TCreateInput, TCreateIdempotencyKey>
      > => {
        const operation = optimism.create(
          variables,
          (variables as TCreateVariables<TCreateInput, string>).input
            .idempotencyKey,
        )
        return operation
      },
      [optimism],
    )

    const onCreateSuccess = useCallback(
      async (
        data: TCreateNetworkObject,
        variables: TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
        context:
          | CreateOperation<
              TCreateVariables<TCreateInput, TCreateIdempotencyKey>
            >
          | undefined,
      ): Promise<void> => {
        await queryClient.invalidateQueries([queryName])
        await Promise.all(
          (additionalInvalidationKeys || []).map((key) =>
            queryClient.invalidateQueries(key),
          ),
        )
        createConfig.options?.onSuccess?.(data, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [
        queryClient,
        queryName,
        createConfig.options,
        additionalInvalidationKeys,
        optimism,
      ],
    )

    const onCreateError = useCallback(
      (
        error: TError,
        variables: TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
        context:
          | CreateOperation<
              TCreateVariables<TCreateInput, TCreateIdempotencyKey>
            >
          | undefined,
      ): void => {
        defaultOnError?.(error, variables, context)
        createConfig.options?.onError?.(error, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [createConfig.options, optimism, defaultOnError],
    )

    const createMutationOptions = useMemo(() => {
      return {
        ...createConfig.options,
        onMutate: onCreateMutate,
        onSuccess: onCreateSuccess,
        onError: onCreateError,
      }
    }, [createConfig.options, onCreateMutate, onCreateSuccess, onCreateError])

    create = useMutation<
      TCreateNetworkObject,
      TError,
      TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
      CreateOperation<TCreateVariables<TCreateInput, TCreateIdempotencyKey>>
    >([`Create${queryName}`], createConfig.fetcher, createMutationOptions)
  }

  /*-------------------*/
  /* Updating
  /*-------------------*/
  let update:
    | UseMutationResult<
        TUpdateNetworkObject,
        TError,
        TUpdateVariables<TUpdateInput, TUpdateContext>,
        UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>>
      >
    | undefined
  if (updateConfig) {
    const onUpdateMutate = useCallback(
      (
        variables: TUpdateVariables<TUpdateInput, TUpdateContext>,
      ): UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>> => {
        const { context, ...vars } = variables
        const operation = optimism.update(variables.input.id, vars, context)
        return operation
      },
      [optimism],
    )

    const onUpdateSuccess = useCallback(
      async (
        data: TUpdateNetworkObject,
        variables: TUpdateVariables<TUpdateInput, TUpdateContext>,
        context:
          | UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>>
          | undefined,
      ): Promise<void> => {
        await queryClient.invalidateQueries([queryName])
        await Promise.all(
          (additionalInvalidationKeys || []).map((key) =>
            queryClient.invalidateQueries(key),
          ),
        )
        updateConfig.options?.onSuccess?.(data, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [
        queryClient,
        queryName,
        updateConfig.options,
        additionalInvalidationKeys,
        optimism,
      ],
    )

    const onUpdateError = useCallback(
      (
        error: TError,
        variables: TUpdateVariables<TUpdateInput, TUpdateContext>,
        context:
          | UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>>
          | undefined,
      ): void => {
        defaultOnError?.(error, variables, context)
        updateConfig.options?.onError?.(error, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [updateConfig.options, optimism, defaultOnError],
    )

    const updateMutationOptions = useMemo(() => {
      return {
        ...updateConfig.options,
        onMutate: onUpdateMutate,
        onSuccess: onUpdateSuccess,
        onError: onUpdateError,
      }
    }, [updateConfig.options, onUpdateMutate, onUpdateSuccess, onUpdateError])

    const fetchWithoutContext: MutationFunction<
      TUpdateNetworkObject,
      TUpdateVariables<TUpdateInput, TUpdateContext>
    > = useCallback(
      (variables) => {
        const { context, ...vars } = variables
        return updateConfig.fetcher(vars)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [updateConfig.fetcher],
    )

    update = useMutation<
      TUpdateNetworkObject,
      TError,
      TUpdateVariables<TUpdateInput, TUpdateContext>,
      UpdateOperation<TUpdateVariables<TUpdateInput, TUpdateContext>>
    >([`Update${queryName}`], fetchWithoutContext, updateMutationOptions)
  }

  /*-------------------*/
  /* Deletion
  /*-------------------*/
  let del:
    | UseMutationResult<
        TDeleteNetworkObject,
        TError,
        TDeleteVariables,
        DeleteOperation
      >
    | undefined
  if (deleteConfig) {
    const onDeleteMutate = useCallback(
      (variables: TDeleteVariables): DeleteOperation => {
        return optimism.delete(variables.input.id)
      },
      [optimism],
    )

    const onDeleteSuccess = useCallback(
      async (
        data: TDeleteNetworkObject,
        variables: TDeleteVariables,
        context: DeleteOperation | undefined,
      ): Promise<void> => {
        await queryClient.invalidateQueries([queryName])
        await Promise.all(
          (additionalInvalidationKeys || []).map((key) =>
            queryClient.invalidateQueries(key),
          ),
        )
        deleteConfig.options?.onSuccess?.(data, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [
        queryClient,
        queryName,
        deleteConfig.options,
        additionalInvalidationKeys,
        optimism,
      ],
    )

    const onDeleteError = useCallback(
      (
        error: TError,
        variables: TDeleteVariables,
        context: DeleteOperation | undefined,
      ): void => {
        defaultOnError?.(error, variables, context)
        deleteConfig.options?.onError?.(error, variables, context)
        if (context !== undefined) {
          optimism.complete(context.operationId)
        }
      },
      [deleteConfig.options, optimism, defaultOnError],
    )

    const deleteMutationOptions = useMemo(() => {
      return {
        ...deleteConfig.options,
        onMutate: onDeleteMutate,
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
      }
    }, [deleteConfig.options, onDeleteMutate, onDeleteSuccess, onDeleteError])

    del = useMutation<
      TDeleteNetworkObject,
      TError,
      TDeleteVariables,
      DeleteOperation
    >([`Delete${queryName}`], deleteConfig.fetcher, deleteMutationOptions)
  }
  /*-------------------*/
  /* Reordering
  /*-------------------*/
  let reorder:
    | {
        mutate: (
          sortOrder: string[],
          group?: string,
        ) => ReorderOperation | undefined
        moveItemUp: (
          existingOrder: string[],
          item: string,
          group?: string,
        ) => ReorderOperation | undefined
        moveItemDown: (
          existingOrder: string[],
          item: string,
          group?: string,
        ) => ReorderOperation | undefined
      }
    | undefined

  if (reorderConfig) {
    const onReorderSuccess = useCallback(
      async (
        data: unknown,
        variables: TReorderVariables,
        context: ReorderOperation | undefined,
      ): Promise<void> => {
        await queryClient.invalidateQueries([queryName])
        await Promise.all(
          (additionalInvalidationKeys || []).map((key) =>
            queryClient.invalidateQueries(key),
          ),
        )
        reorderConfig.options?.onSuccess?.(data, variables, context)
      },
      [
        queryClient,
        queryName,
        reorderConfig.options,
        additionalInvalidationKeys,
      ],
    )

    const onReorderError = useCallback(
      (
        error: TError,
        variables: TReorderVariables,
        context: ReorderOperation | undefined,
      ): void => {
        reorderConfig.options?.onError?.(error, variables, context)
      },
      [reorderConfig.options],
    )

    const onReorderSettled = useCallback((): void => {
      const completedReorderOperations = optimism.operations.filter(
        (operation) =>
          operation.type === "reorder" && operation.settled === true,
      )
      completedReorderOperations.forEach((operation) => {
        optimism.complete(operation.operationId)
      })
    }, [optimism])

    const reorderMutationOptions = useMemo(() => {
      return {
        ...reorderConfig.options,
        onSuccess: onReorderSuccess,
        onError: onReorderError,
        onSettled: onReorderSettled,
      }
    }, [
      reorderConfig.options,
      onReorderSuccess,
      onReorderError,
      onReorderSettled,
    ])

    const { mutate, ...rest } = useMutation<
      unknown,
      TError,
      TReorderVariables,
      ReorderOperation
    >([`Reorder${queryName}`], reorderConfig.fetcher, reorderMutationOptions)

    const reorderMutateOverride = useCallback(
      (sortOrder: string[], group?: string): ReorderOperation => {
        const operation = optimism.reorder(
          sortOrder,
          () => {
            mutate({
              input: {
                sortOrder,
                group,
              },
            })
          },
          group,
        )
        return operation
      },
      [optimism, mutate],
    )

    const moveItemUp = useCallback(
      (existingOrder: string[], item: string, group?: string) => {
        const newSortOrder = moveObjectUp(existingOrder, item)
        return reorderMutateOverride(newSortOrder, group)
      },
      [reorderMutateOverride],
    )

    const moveItemDown = useCallback(
      (existingOrder: string[], item: string, group?: string) => {
        const newSortOrder = moveObjectDown(existingOrder, item)
        return reorderMutateOverride(newSortOrder, group)
      },
      [reorderMutateOverride],
    )

    reorder = useMemo(() => {
      return {
        mutate: reorderMutateOverride,
        moveItemUp,
        moveItemDown,
        ...rest,
      }
    }, [reorderMutateOverride, moveItemUp, moveItemDown, rest])
  }

  return {
    create: create as NonNullable<typeof create>,
    update: update as NonNullable<typeof update>,
    delete: del as NonNullable<typeof del>,
    reorder: reorder as NonNullable<typeof reorder>,
  }
}
