/* eslint-disable react-hooks/rules-of-hooks */
import {
  InfiniteData,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query"
import { useCallback, useMemo } from "react"
import { useOptimisticBuffer } from "./provider"
import { applyOperations } from "./apply-operations"
import { Operation } from "./types"

export type TCreateVariables<
  TInput,
  TIdempotencyKey extends string | undefined,
> = {
  input: TIdempotencyKey extends string
    ? { create: TInput; idempotencyKey: TIdempotencyKey }
    : { create: TInput }
}
export type TUpdateVariables<TInput, TUpdateContext> = {
  input: { id: string; patch: TInput }
  context?: TUpdateContext
}
export type TReorderVariables = {
  input: {
    sortOrder: string[]
    group?: string
  }
}

export type OptimisticQueryConfig<
  TQueryVariables extends
    | {
        [key: string]: unknown
      }
    | undefined,
  TQueryNetworkObject,
  TDomainObject extends { id: string },
  TError,
  TCreateInput,
  TCreateIdempotencyKey extends string | undefined,
  TUpdateInput,
  TUpdateContext,
  TTemporaryObject extends { id: string } = TDomainObject,
> = {
  queryName: string
  variables: TQueryVariables

  fetcher: QueryFunction<TQueryNetworkObject, QueryKey>
  options?: Omit<
    UseInfiniteQueryOptions<TQueryNetworkObject, TError, TDomainObject[]>,
    "select"
  >

  getPageInfo: (data: TQueryNetworkObject) =>
    | {
        endCursor?: string | null | undefined
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null | undefined
      }
    | undefined

  fromNetworkObject: (
    data: InfiniteData<TQueryNetworkObject>,
    pendingOperations: Operation<
      TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
      TUpdateVariables<TUpdateInput, TUpdateContext>,
      TUpdateContext
    >[],
  ) => TDomainObject[]

  transform?: (data: TDomainObject[]) => TDomainObject[]

  createTemporaryDomainObject?: (
    createVariables: TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
    domainObjects: TDomainObject[],
  ) => TTemporaryObject | null
  applyTemporaryDomainObject?: (
    temporaryDomainObject: TTemporaryObject,
    domainObjects: TDomainObject[],
  ) => TDomainObject[]

  findDomainObjectToPatch?: (
    domainObjects: TDomainObject[],
    patch: TUpdateVariables<TUpdateInput, TUpdateContext>,
  ) => number
  applyPatch?: (
    domainObject: TDomainObject,
    patch: TUpdateVariables<TUpdateInput, TUpdateContext>,
  ) => TDomainObject

  deleteObject?: (domainObjects: TDomainObject[], id: string) => TDomainObject[]

  sort?: (
    domainObjects: TDomainObject[],
    order: string[],
    idKey: string,
    group?: string,
  ) => TDomainObject[]
}

export const useOptimisticQuery = <
  TQueryVariables extends
    | {
        [key: string]: unknown
      }
    | undefined,
  TQueryNetworkObject,
  TDomainObject extends { id: string },
  TError,
  TCreateInput,
  TCreateIdempotencyKey extends string | undefined,
  TUpdateInput,
  TUpdateContext,
  TTemporaryObject extends { id: string } = TDomainObject,
>(
  config: OptimisticQueryConfig<
    TQueryVariables,
    TQueryNetworkObject,
    TDomainObject,
    TError,
    TCreateInput,
    TCreateIdempotencyKey,
    TUpdateInput,
    TUpdateContext,
    TTemporaryObject
  >,
) => {
  const {
    queryName,
    variables,
    fetcher,
    options,
    getPageInfo,
    fromNetworkObject,
    transform,
    createTemporaryDomainObject,
    applyTemporaryDomainObject,
    findDomainObjectToPatch,
    applyPatch,
    deleteObject,
    sort,
  } = config

  const optimism = useOptimisticBuffer<
    TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
    TUpdateVariables<TUpdateInput, TUpdateContext>,
    TUpdateContext
  >(queryName)

  /*-------------------*/
  /* Listing
  /*-------------------*/
  const getNextPageParam = useNextPageParam({
    variables,
    getPageInfo,
  })
  const getPreviousPageParam = usePreviousPageParam({
    variables,
    getPageInfo,
  })

  const queryOptionsWithSelect: UseInfiniteQueryOptions<
    TQueryNetworkObject,
    TError,
    TDomainObject[]
  > = useMemo(() => {
    return {
      refetchOnMount: false,
      ...options,
      select: (data: InfiniteData<TQueryNetworkObject>) => {
        return {
          pages: [
            applyOperations<
              TQueryNetworkObject,
              TDomainObject,
              TCreateVariables<TCreateInput, TCreateIdempotencyKey>,
              TUpdateVariables<TUpdateInput, TUpdateContext>,
              TUpdateContext,
              TTemporaryObject
            >(
              data,
              optimism.operations,
              fromNetworkObject,
              createTemporaryDomainObject,
              applyTemporaryDomainObject,
              findDomainObjectToPatch,
              applyPatch,
              transform,
              deleteObject,
              sort,
            ),
          ],
          pageParams: data.pages
            ? [
                {
                  nextPage: getNextPageParam(data.pages[data.pages.length - 1]),
                  previousPage: getPreviousPageParam(data.pages[0]),
                },
              ]
            : [],
        }
      },
      getNextPageParam,
      getPreviousPageParam,
    }
  }, [
    options,
    fromNetworkObject,
    optimism.operations,
    createTemporaryDomainObject,
    applyTemporaryDomainObject,
    findDomainObjectToPatch,
    applyPatch,
    transform,
    deleteObject,
    sort,
    getNextPageParam,
    getPreviousPageParam,
  ])

  const { data, ...restOfQuery } = useInfiniteQuery<
    TQueryNetworkObject,
    TError,
    TDomainObject[]
  >(
    variables === undefined ? [queryName] : [queryName, variables],
    fetcher,
    queryOptionsWithSelect,
  )

  return useMemo(() => {
    return {
      ...restOfQuery,
      data: data?.pages[0] || [],
    }
  }, [data, restOfQuery])
}

export const useNextPageParam = <
  TQueryNetworkObject,
  TQueryVariables extends
    | {
        [key: string]: unknown
      }
    | undefined,
>(config: {
  variables: TQueryVariables
  getPageInfo: (data: TQueryNetworkObject) =>
    | {
        endCursor?: string | null | undefined
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null | undefined
      }
    | undefined
}) => {
  const getNextPageParam = useCallback(
    (lastPage: TQueryNetworkObject): TQueryVariables | undefined => {
      const pageInfo = config.getPageInfo(lastPage)
      if (!pageInfo) return undefined
      const { endCursor, hasNextPage } = pageInfo
      if (!endCursor || !hasNextPage) return undefined

      if ((config.variables as { before: string })?.before) {
        // Paginating backwards
        return {
          ...config.variables,
          before: endCursor,
        }
      } else {
        // Paginating forwards
        return {
          ...config.variables,
          after: endCursor,
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.variables, config.getPageInfo],
  )

  return getNextPageParam
}

export const usePreviousPageParam = <
  TQueryNetworkObject,
  TQueryVariables extends
    | {
        [key: string]: unknown
      }
    | undefined,
>(config: {
  variables: TQueryVariables
  getPageInfo: (data: TQueryNetworkObject) =>
    | {
        endCursor?: string | null | undefined
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null | undefined
      }
    | undefined
}) => {
  const getPreviousPageParam = useCallback(
    (firstPage: TQueryNetworkObject): TQueryVariables | undefined => {
      const pageInfo = config.getPageInfo(firstPage)
      if (!pageInfo) return undefined
      const { startCursor, hasPreviousPage } = pageInfo
      if (!startCursor || !hasPreviousPage) return undefined

      if ((config.variables as { before: string })?.before) {
        // Paginating backwards
        return {
          ...config.variables,
          after: startCursor,
        }
      } else {
        // Paginating forwards
        return {
          ...config.variables,
          before: startCursor,
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.variables, config.getPageInfo],
  )

  return getPreviousPageParam
}
